import { type FacilityNote } from "@src/appV2/Facilities/types";
import { WorkplaceNoteIdentifier } from "@src/appV2/redesign/Workplace/constants";
import { isEmpty, keyBy } from "lodash";

const getNotesByCategory = (notes, key) => {
  if (isEmpty(notes)) {
    return "";
  }
  const obj = keyBy(notes, "identifier");
  return (obj && obj[key]) || "";
};

const getCheckInInstructionsObj = (facilityNotes: FacilityNote[]) => {
  return {
    CHECK_IN_INST: getNotesByCategory(facilityNotes, WorkplaceNoteIdentifier.CHECK_IN_INSTRUCTIONS),
    CHECK_IN_INST_PARKING: getNotesByCategory(
      facilityNotes,
      WorkplaceNoteIdentifier.PARKING_INSTRUCTIONS
    ),
    CHECK_IN_INST_ENTRANCE: getNotesByCategory(
      facilityNotes,
      WorkplaceNoteIdentifier.ENTRANCE_INSTRUCTIONS
    ),
    CHECK_IN_INST_ORIENTATION: getNotesByCategory(
      facilityNotes,
      WorkplaceNoteIdentifier.ORIENTATION_INSTRUCTIONS
    ),
    CHECK_IN_INST_EARLYCHECKIN: getNotesByCategory(
      facilityNotes,
      WorkplaceNoteIdentifier.EARLY_CHECK_IN_INSTRUCTIONS
    ),
    CHECK_IN_INST_FIRSTSHIFT: getNotesByCategory(
      facilityNotes,
      WorkplaceNoteIdentifier.FIRST_SHIFT_INSTRUCTIONS
    ),
    CHECK_IN_INST_TIMECARD: getNotesByCategory(
      facilityNotes,
      WorkplaceNoteIdentifier.TIMECARD_INSTRUCTIONS
    ),
    CHECK_IN_INST_DRESS: getNotesByCategory(
      facilityNotes,
      WorkplaceNoteIdentifier.DRESS_CODE_INSTRUCTIONS
    ),
    CHECK_IN_INST_OTHER: getNotesByCategory(
      facilityNotes,
      WorkplaceNoteIdentifier.OTHER_INSTRUCTIONS
    ),
  };
};

export { getNotesByCategory, getCheckInInstructionsObj };
